import Title from "./components/Title/Title";
import Paragraph from "./components/Paragraph/Paragraph";
import Button from "./components/Button/Button";
import OptionsItem from "./components/OptionsItem/OptionsItem";
import React, { useEffect, useState } from "react";
import imageStartScreen from "./assets/images/00.webp";
import imageResult from "./assets/images/9.webp";
import styles from "./App.module.scss";
import questions from "./state/questions";
import classNames from "classnames";
import Result from "./components/Result/Result";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0); // считаем номер вопроса
  const [stateFlow, setStateFlow] = useState("Start"); // состояние флоу: стартовый экран / вопросы / результат
  const [option, setOption] = useState(false); // выбран ли ответ
  const [resultState, setResultState] = useState(""); // выводить ли результат
  const [resultCount, setResultCount] = useState(0); // счетчик правильных вопросов
  const phone = document.getElementById("phone"); // находим область телефона на десктопе

  // console.log(resultCount); // тестирование логики

  // прокрутка вниз при нажатии на ответ
  const optionActiveHandler = (option) => {
    setOption(option);

    setTimeout(() => {
      window.scrollTo({ top: 1000, behavior: "smooth" });
      phone.scrollTo({ top: 1000, behavior: "smooth" });
    }, "50");
  };

  const resultCountLogic = (result) => {
    setResultCount(resultCount + result);
  };

  // поднимаемся наверх после перехода на страницу
  const scrollReset = (event) => {
    window.scrollTo(0, 0);
    phone.scrollTo(0, 0);
  };

  // Скролл наверх при нажатии старт квиз
  const startFlow = () => {
    setStateFlow("Flow");
    window.scrollTo(0, 0);
  };

  // логика флоу
  const handlerNext = () => {
    if (currentQuestion === 4) {
      setStateFlow("End");

      setResultState(() => {
        if (resultCount <= 1) {
          return (
            <Result
              score={resultCount + "/5"}
              title="Masih ada banyak hal yang perlu Anda ketahui tentang tren mode. Oleh karena itu, jangan buang waktu lagi!"
              text={
                "Salin kode promo, masuk melalui tautan, dan pilih kaus serta bandana trendi Maxim dengan diskon 10%."
              }
              imageSrc={imageResult}
              posterContainer={styles.posterContainer}
              optionsContainer={styles.optionsContainer}
              handlerBack={handlerBack}
            />
          );
        }

        if (resultCount >= 2 && resultCount <= 3) {
          return (
            <Result
              score={resultCount + "/5"}
              title="Mode tidak berjalan di tempat dan Anda nyaris mengejarnya. Jangan ketinggalan!"
              text={
                "Aktifkan kode promo dan dapatkan diskon 10% untuk kaus dan bandana. Ikuti tren, masuk melalui tautan, dan beli produk berlogo perusahaan dengan harga jauh lebih murah."
              }
              imageSrc={imageResult}
              posterContainer={styles.posterContainer}
              optionsContainer={styles.optionsContainer}
              handlerBack={handlerBack}
            />
          );
        }

        if (resultCount >= 4 && resultCount <= 5) {
          return (
            <Result
              score={resultCount + "/5"}
              title="Kelihatannya kamu adalah pakar mode sejati! Kami pasti akan meminta pendapat Anda saat akan membuat koleksi terbaru."
              text={
                "Sementara itu, salin kode promo karena kode ini memberikan diskon 10% untuk kaus dan bandana. Masuk melalui tautan dan beli produk trendi dengan harga jauh lebih murah."
              }
              imageSrc={imageResult}
              posterContainer={styles.posterContainer}
              optionsContainer={styles.optionsContainer}
              handlerBack={handlerBack}
            />
          );
        }
      });

      // сбрасываем  скролл для результата
      scrollReset();
    } else {
      setOption(false);
      setCurrentQuestion(currentQuestion + 1);

      // прокрутка вверх после нажатия на ответ
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        phone.scrollTo({ top: 0, behavior: "smooth" });
      }, "50");
    }
  };

  // возвращение в начало
  const handlerBack = () => {
    setOption(false);
    setStateFlow("Start");
    setCurrentQuestion(0);
    setResultCount(0);
  };

  // считаем цифры прокрутки и верешаем options::after
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.wrapper}>
        <div className={styles.phone} id="phone">
          {stateFlow === "Start" ? (
            <>
              <div className={styles.posterContainer}>
                <img src={imageStartScreen} className={styles.image} alt="" />
              </div>
              <div
                className={classNames({
                  [styles.optionsContainer]: true,
                  [styles.onScrollOption]: offset,
                })}
              >
                <div className={styles.bgWhite}>
                  <div className={styles.contentWrapper}>
                    <Title value="Kami hadiahkan diskon 10% untuk kaus dan bandana" />
                    <Paragraph value="Maxim mempersembahkan koleksi terbaru pakaian dan aksesori bermerek bagi yang berada di balik kemudi maupun yang membonceng di belakangnya." />
                    <Paragraph value="Ketahui selengkapnya tentang tren mode bersama layanan transportasi online Maxim dan dapatkan diskon 10% untuk kaus dan bandana di lokapasar Shopee Mall dan Tokopedia." />
                  </div>
                  <Button
                    value="Ayo berangkat"
                    onClick={() => startFlow()}
                    id="start"
                  />
                </div>
              </div>
            </>
          ) : stateFlow === "Flow" ? (
            <>
              <div className={styles.posterContainer}>
                <img
                  src={questions[currentQuestion].image}
                  className={styles.image}
                  alt={questions[currentQuestion].image}
                />
              </div>
              <div
                className={classNames({
                  [styles.optionsContainer]: true,
                  [styles.onScrollOption]: offset,
                })}
              >
                <div className={styles.bgWhite}>
                  <div className={styles.header}>
                    <div className={styles.topBarContainer}>
                      <div>
                        Pertanyaan {currentQuestion + 1} of {questions.length}
                      </div>
                    </div>
                    <Title value={questions[currentQuestion].questionText} />
                  </div>

                  <div className={styles.optionsWrapper}>
                    {questions[currentQuestion].answerOptions.map((item) => (
                      <OptionsItem
                        key={item.answerText}
                        title={item.answerText}
                        resultText={item.answerResult}
                        onClick={optionActiveHandler}
                        disabled={option}
                        result={item.result}
                        resultCountLogic={resultCountLogic}
                      />
                    ))}
                  </div>

                  <Button
                    value="Berikutnya"
                    onClick={() => handlerNext()}
                    disabled={!option}
                    id={"btn" + (currentQuestion + 1)}
                  />
                </div>
              </div>
            </>
          ) : (
            <>{resultState}</>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
