const questions = [
  // 1 Слайд \\ Футболка [1]
  {
    questionText:
      "Mulai tahun 2000, Institut Warna Pantone setiap tahunnya memilih satu warna paling tren. Warna hitam kaus kami adalah simbol keeleganan dan kepraktisan. Tetapi, berapa kali warna ini sudah menjadi warna tahun ini menurut Pantone?",
    answerOptions: [
      {
        answerText: "0",
        answerResult:
          "Warna hitam dicintai oleh jutaan orang di seluruh dunia, tetapi Institut Warna Pantone tidak pernah menganugerahi warna ini dengan gelar “Warna Tahun Ini”.",
        result: true,
      },
      {
        answerText: "1",
        answerResult:
          "Warna hitam dicintai oleh jutaan orang di seluruh dunia, tetapi Institut Warna Pantone tidak pernah menganugerahi warna ini dengan gelar “Warna Tahun Ini”.",
        result: false,
      },
      {
        answerText: "3",
        answerResult:
          "Warna hitam dicintai oleh jutaan orang di seluruh dunia, tetapi Institut Warna Pantone tidak pernah menganugerahi warna ini dengan gelar “Warna Tahun Ini”.",
        result: false,
      },
      {
        answerText: "6",
        answerResult:
          "Warna hitam dicintai oleh jutaan orang di seluruh dunia, tetapi Institut Warna Pantone tidak pernah menganugerahi warna ini dengan gelar “Warna Tahun Ini”.",
        result: false,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд \\ Дождевик [4]
  {
    questionText:
      "Tokoh dalam jas hujan berwarna kuning sudah sejak lama menjadi bagian dari budaya pop. Kami melihatnya di poster film “IT”, jas hujan warna ini juga digunakan oleh Coraline dan Winnie the Pooh. Jadi, kami pun membuat kostum tahan air kami ini dengan warna kuning mentereng. Mengapa begitu?",
    answerOptions: [
      {
        answerText:
          "Karena kuning adalah warna tradisional taksi di seluruh dunia.",
        answerResult:
          "Jas hujan bisa berwarna apa saja — ini tidak akan memengaruhi sifatnya yang tahan air. Oleh karena itu, kami memilih kuning sebagai warna perusahaan.",
        result: false,
      },
      {
        answerText:
          "Karena hanya bahan berwarna kuning yang memiliki sifat antiair.",
        answerResult:
          "Jas hujan bisa berwarna apa saja — ini tidak akan memengaruhi sifatnya yang tahan air. Oleh karena itu, kami memilih kuning sebagai warna perusahaan.",
        result: false,
      },
      {
        answerText: "Warna ini menang dalam pemungutan suara di komunitas.",
        answerResult:
          "Jas hujan bisa berwarna apa saja — ini tidak akan memengaruhi sifatnya yang tahan air. Oleh karena itu, kami memilih kuning sebagai warna perusahaan.",
        result: false,
      },
      {
        answerText:
          "Karena kuning adalah warna perusahaan layanan order transportasi Maxim.",
        answerResult:
          "Jas hujan bisa berwarna apa saja — ini tidak akan memengaruhi sifatnya yang tahan air. Oleh karena itu, kami memilih kuning sebagai warna perusahaan.",
        result: true,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд \\ Шлемы и куртки [1]
  {
    questionText:
      "Koleksi kami bukan cuma bergaya, melainkan juga berguna. Pengemudi yang melaksanakan order dengan mengenakan seragam lengkap yang terdiri atas jaket dan helm dapat mengandalkan prioritas. Apa artinya?",
    answerOptions: [
      {
        answerText:
          "Prioritas meningkatkan peluang untuk memperoleh order yang disukai.",
        answerResult:
          "Jika terdapat dua pengemudi berada dalam jarak yang sama dari alamat pengiriman, yang pertama akan mendapatkan order adalah yang memiliki prioritas.",
        result: true,
      },
      {
        answerText:
          "Kendaraan lain harus memberikan jalan kepada pengemudi seperti ini.",
        answerResult:
          "Jika terdapat dua pengemudi berada dalam jarak yang sama dari alamat pengiriman, yang pertama akan mendapatkan order adalah yang memiliki prioritas.",
        result: false,
      },
      {
        answerText:
          "Pengemudi-pengemudi seperti ini mendapatkan diskon untuk pemesanan pakaian Maxim yang berikutnya.",
        answerResult:
          "Jika terdapat dua pengemudi berada dalam jarak yang sama dari alamat pengiriman, yang pertama akan mendapatkan order adalah yang memiliki prioritas.",
        result: false,
      },
      {
        answerText:
          "Prioritas memberikan hak untuk melakukan pemeliharaan teknis tanpa antrean.",
        answerResult:
          "Jika terdapat dua pengemudi berada dalam jarak yang sama dari alamat pengiriman, yang pertama akan mendapatkan order adalah yang memiliki prioritas.",
        result: false,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд \\ Поясная сумка [3]
  {
    questionText:
      "Tas pinggang sangat digemari oleh orang-orang yang ingin agar kedua tangan mereka bebas: pengendara motor, kurir, penjual. Tetapi, kemunculan aksesori ini dalam bentuk modernnya adalah berkat suatu kegiatan yang lain daripada yang disebutkan. Yang mana yang dimaksud?",
    answerOptions: [
      {
        answerText: "Pilot",
        answerResult:
          "Tas pinggang kulit mulai populer di antara pemain ski sekitar tahun 60-an. Siapa pun pasti setuju kalau meluncur dari gunung sambil membawa tas tangan pastinya kurang nyaman, bukan?",
        result: false,
      },
      {
        answerText: "Juru masak",
        answerResult:
          "Tas pinggang kulit mulai populer di antara pemain ski sekitar tahun 60-an. Siapa pun pasti setuju kalau meluncur dari gunung sambil membawa tas tangan pastinya kurang nyaman, bukan?",
        result: false,
      },
      {
        answerText: "Pemain ski",
        answerResult:
          "Tas pinggang kulit mulai populer di antara pemain ski sekitar tahun 60-an. Siapa pun pasti setuju kalau meluncur dari gunung sambil membawa tas tangan pastinya kurang nyaman, bukan?",
        result: true,
      },
      {
        answerText: "Penabuh drum",
        answerResult:
          "Tas pinggang kulit mulai populer di antara pemain ski sekitar tahun 60-an. Siapa pun pasti setuju kalau meluncur dari gunung sambil membawa tas tangan pastinya kurang nyaman, bukan?",
        result: false,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд \\ Бандана [2]
  {
    questionText:
      "Bandana adalah teman setia para pengemudi motor dan aksesori bergaya untuk para pendampingnya. Kita sendiri jarang berpikir mengapa kain ini disebut demikian.",
    answerOptions: [
      {
        answerText: "Dari bahasa Inggris To Bound (mengikat)",
        answerResult:
          "Terlepas dari asal katanya yang berasal dari bahasa Hindi, “Bandana”, aksesori ini meraih popularitasnya berkat para koboi amerika. Juga para pengemudi kami, tentunya.",
        result: false,
      },
      {
        answerText: "Dari bahasa Hindi - «bāṅdhnū» - simpul",
        answerResult:
          "Terlepas dari asal katanya yang berasal dari bahasa Hindi, “Bandana”, aksesori ini meraih popularitasnya berkat para koboi amerika. Juga para pengemudi kami, tentunya.",
        result: true,
      },
      {
        answerText: "Dari kata Band (Band)",
        answerResult:
          "Terlepas dari asal katanya yang berasal dari bahasa Hindi, “Bandana”, aksesori ini meraih popularitasnya berkat para koboi amerika. Juga para pengemudi kami, tentunya.",
        result: false,
      },
      {
        answerText: "Dari kata “Pisang” karena dihiasi gambar pisang.",
        answerResult:
          "Terlepas dari asal katanya yang berasal dari bahasa Hindi, “Bandana”, aksesori ini meraih popularitasnya berkat para koboi amerika. Juga para pengemudi kami, tentunya.",
        result: false,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
