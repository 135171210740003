import React, { useState, useEffect, useRef } from "react";
import Title from "../Title/Title";
import Desc from "../Desc/Desc";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { FacebookIcon, WhatsappIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
}) {
  const [offset, setOffset] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [showConditions, setShowConditions] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    console.log(textAreaRef.current.value);
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(
      <div className={styles.copySuccess}>Промокод скопирован.</div>
    );

    setTimeout(() => {
      setCopySuccess(
        <div className={classNames(styles.copySuccess, styles.displayNone)}>
          Промокод скопирован.
        </div>
      );
    }, "1500");
  }

  return (
    <>
      {!showConditions ? (
        <>
          <div className={posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={classNames(optionsContainer, styles.before)}>
            <div
              className={classNames({
                [styles.resultPosterContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.contentWrapper}>
                <div className={styles.score}>{score}</div>
                <Title value={title} />
                {text}
                <Desc
                  value={
                    "Untuk aktivasi kode promo, tambahkan produk ke keranjang dan masukkan kode ke kolom khusus yang tersedia."
                  }
                />
              </div>

              <div className={styles.promoBox}>
                <div className={styles.promoButtonsWrapper}>
                  <div className={styles.promoWrapper}>
                    <div className={styles.inputPromoWrapper}>
                      <form className={styles.form}>
                        <input
                          ref={textAreaRef}
                          value="MAXIMQUIZ"
                          className={styles.inputPromo}
                          readOnly
                        />
                      </form>

                      <button onClick={copyToClipboard} className={styles.copy}>
                        <ContentCopyIcon />
                      </button>
                    </div>
                  </div>

                  <div className={styles.buttonBox}>
                    <a
                      href="https://shopee.co.id/universal-link/maxim_indonesia?categoryid=100011&deep_and_web=1&entrypoint=shopbypdp&itemid=11798920869&smtt=9&utm_campaign=s462764549_ss_id_webs_quiz1&utm_medium=seller&utm_source=website"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        value="Terapkan kode promo"
                        className={styles.buttonPresent}
                      />
                    </a>

                    <div
                      className={styles.buttonBoxLink}
                      onClick={() => {
                        setShowConditions(true);
                      }}
                    >
                      Ketentuan promosi
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classNames(
                  styles.contentWrapper,
                  styles.socialWrapper
                )}
              >
                <Paragraph value="Berbagi hasil Anda" />
                <div className={styles.social}>
                  <FacebookShareButton
                    url={SITE_URL}
                    title="Aku mau kasih saran kuis Maxim, nih. Di akhir ada diskon 10% untuk merchandise perusahaan. Masuk melalui tautan 👇"
                    image={ImageURL}
                    hashtag="#Maxim"
                    id="facebook"
                  >
                    <FacebookIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.facebook)}
                    />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={SITE_URL}
                    title="Aku mau bagikan metode paling mudah untuk dapat diskon 10% untuk merchandise Maxim, nih! Cukup ikuti kuis dan di akhir kamu bisa dapat kode promo untuk diskon 💥"
                    image={ImageURL}
                    id="whatsapp"
                  >
                    <WhatsappIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.whatsapp)}
                    />
                  </WhatsappShareButton>

                  <div id="sharelink">
                    <IconButton
                      onClick={testClick}
                      color="primary"
                      className={styles.share}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Snackbar
                      message="Disalin ke papan kliping"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1000}
                      onClose={() => setOpen(false)}
                      open={open}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {copySuccess}
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer} m2>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <Title value="Syarat dan Ketentuan Umum Shopee Quiz" />
                <Paragraph value="Syarat dan ketentuan berikut ini («S&K» / «Syarat dan Ketentuan») berlaku untuk kontes kuis online («Kuis») yang diselenggarakan oleh PT Teknologi Perdana Indonesia (Maxim Indonesia) («Penyelenggara») di Toko Shopee mereka." />
                <Paragraph value="Berikut ini adalah ketentuan yang berlaku untuk Kuis:" />
                <ul>
                  <li>
                    <Paragraph value="Setiap individu yang merupakan warga negara Indonesia dan pada tanggal partisipasi telah mencapai usia 18 tahun dapat berpartisipasi dalam kompetisi Kuis ini melalui toko online resmi dari Shopee yang dibuat oleh Penyelenggara  («Toko Shopee»), kecuali karyawan, distributor, kontraktor, dan vendor Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Semua orang yang tertarik untuk berpartisipasi dalam Kuis ini («Peserta») harus mengikuti instruksi yang disebutkan dalam tautan atau di Toko Shopee untuk menyelesaikan Kuis." />
                  </li>
                  <li>
                    <Paragraph value="«Peserta yang Memenuhi Syarat» adalah semua individu yang mengikuti Kuis melalui Toko Shopee akan menjadi Peserta yang Memenuhi Syarat segera setelah mereka memberikan informasi yang diminta oleh penyelenggara dalam S&K dan Toko Shopee, menjawab jawaban yang benar secara maksimal untuk pertanyaan- pertanyaan dalam Kuis dan mematuhi S&K." />
                  </li>
                  <li>
                    <Paragraph value="Semua Peserta diwajibkan untuk memberikan nama lengkap, nama perusahaan, ID email yang valid, dan nomor ponsel." />
                  </li>
                  <li>
                    <Paragraph value="Kuis akan dilaksanakan sesuai dengan instruksi yang diberikan di Toko Shopee, hanya untuk produk T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk meminta bukti identitas yang sah dari pemenang untuk memberikan kode promosi yaitu MAXIMQUIZ, dengan hadiah potongan diskon 10% dari total pembelian." />
                  </li>
                  <li>
                    <Paragraph value="Hadiah tidak dapat ditukar, tidak dapat dikembalikan, tidak dapat dipindahtangankan, dan tidak dapat dialihkan. Hadiah juga tidak dapat ditukar dengan uang tunai. Hanya pemenang hadiah dan tidak ada orang lain atau agen yang dapat mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk mengganti kode promosi dengan hadiah lain dengan nilai yang sama, sebagaimana ditentukan oleh Penyelenggara atas kebijakannya sendiri." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja, untuk membatalkan entri (dan peserta yang mengirimkan atau menyebabkan entri tersebut dikirimkan) yang dicurigai telah dikirimkan: (a) menggunakan informasi yang salah, tidak benar, curang, atau menyesatkan, termasuk namun tidak terbatas pada detail pribadi dan informasi kontak; dan/atau (b) dengan menggunakan beberapa identitas, alamat email, atau akun; dan/atau (c) dengan cara apa pun yang bertentangan dengan Syarat dan Ketentuan ini." />
                  </li>
                  <li>
                    <Paragraph value="Peserta setuju bahwa tidak ada klarifikasi yang akan diberikan oleh Penyelenggara atas pertanyaan dan jawaban yang digunakan dalam Kuis." />
                  </li>
                  <li>
                    <Paragraph value="Jika terdapat perselisihan mengenai identitas Peserta yang Memenuhi Syarat, penyelenggara berhak, atas kebijakannya sendiri, untuk menentukan identitas Peserta yang Memenuhi Syarat." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja selama, atau setelah tanggal penutupan kompetisi, untuk memverifikasi keabsahan entri dan Peserta yang Memenuhi Syarat (termasuk identitas Peserta yang Memenuhi Syarat dan tempat tinggal) dan mendiskualifikasi Peserta yang Memenuhi Syarat yang mengirimkan entri yang tidak sesuai dengan Syarat dan Ketentuan ini atau yang mengutak-atik proses entri. Kegagalan Penyelenggara untuk menegakkan hak-haknya pada tahap apa pun bukan merupakan pengabaian atas hak-hak tersebut." />
                  </li>
                  <li>
                    <Paragraph value="Kewajiban pajak yang timbul bagi pemenang hadiah akan dibayarkan oleh pemenang sebelum mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Kuis ini dilaksanakan sampai dengan 15 Agustus 2024." />
                  </li>
                </ul>
                <Paragraph value="Penjurian akan dilakukan oleh Penyelenggara, sesuai dengan instruksi dari Kuis ini yang diumumkan oleh Tim Penyelenggara di Toko Shopee Penyelenggara dan berdasarkan jawaban benar maksimum yang diposting atau dipilih." />
                <br />
                <Title value="Syarat dan Ketentuan Umum Tokopedia Quiz" />
                <Paragraph value="Syarat dan ketentuan berikut ini («S&K» / «Syarat dan Ketentuan») berlaku untuk kontes kuis online («Kuis»)yang diselenggarakan oleh PT Teknologi Perdana Indonesia (Maxim Indonesia) («Penyelenggara») di Tokopedia mereka." />
                <Paragraph value="Berikut ini adalah ketentuan yang berlaku untuk Kuis:" />
                <ul>
                  <li>
                    <Paragraph value="Setiap individu yang merupakan warga negara Indonesia dan pada tanggal partisipasi telah mencapai usia 18 tahun dapat berpartisipasi dalam kompetisi Kuis ini melalui online resmi dari Tokopedia yang dibuat oleh Penyelenggara («Tokopedia»), kecuali karyawan, distributor, kontraktor, dan vendor Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Semua orang yang tertarik untuk berpartisipasi dalam Kuis ini («Peserta») harus mengikuti instruksi yang disebutkan dalam tautan atau di Tokopedia untuk menyelesaikan Kuis." />
                  </li>
                  <li>
                    <Paragraph value="«Peserta yang Memenuhi Syarat» adalah semua individu yang mengikuti Kuis melalui Tokopedia akan menjadi Peserta yang Memenuhi Syarat segera setelah mereka memberikan informasi yang diminta oleh penyelenggara dalam S&K dan Tokopedia, menjawab jawaban yang benar secara maksimal untuk pertanyaan- pertanyaan dalam Kuis dan mematuhi S&K." />
                  </li>
                  <li>
                    <Paragraph value="Semua Peserta diwajibkan untuk memberikan nama lengkap, nama perusahaan, ID email yang valid, dan nomor ponsel." />
                  </li>
                  <li>
                    <Paragraph value="Kuis akan dilaksanakan sesuai dengan instruksi yang diberikan di Tokopedia, hanya untuk produk T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk meminta bukti identitas yang sah dari pemenang untuk memberikan kode promosi yaitu MAXIMQUIZ, dengan hadiah potongan diskon 10% dari total pembelian." />
                  </li>
                  <li>
                    <Paragraph value="Hadiah tidak dapat ditukar, tidak dapat dikembalikan, tidak dapat dipindahtangankan, dan tidak dapat dialihkan. Hadiah juga tidak dapat ditukar dengan uang tunai. Hanya pemenang hadiah dan tidak ada orang lain atau agen yang dapat mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk mengganti kode promosi dengan hadiah lain dengan nilai yang sama, sebagaimana ditentukan oleh Penyelenggara atas kebijakannya sendiri." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja, untuk membatalkan entri (dan peserta yang mengirimkan atau menyebabkan entri tersebut dikirimkan) yang dicurigai telah dikirimkan: (a) menggunakan informasi yang salah, tidak benar, curang, atau menyesatkan, termasuk namun tidak terbatas pada detail pribadi dan informasi kontak; dan/atau (b) dengan menggunakan beberapa identitas, alamat email, atau akun; dan/atau (c) dengan cara apa pun yang bertentangan dengan Syarat dan Ketentuan ini." />
                  </li>
                  <li>
                    <Paragraph value="Peserta setuju bahwa tidak ada klarifikasi yang akan diberikan oleh Penyelenggara atas pertanyaan dan jawaban yang digunakan dalam Kuis." />
                  </li>
                  <li>
                    <Paragraph value="Jika terdapat perselisihan mengenai identitas Peserta yang Memenuhi Syarat, penyelenggara berhak, atas kebijakannya sendiri, untuk menentukan identitas Peserta yang Memenuhi Syarat." />
                  </li>

                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja selama, atau setelah tanggal penutupan kompetisi, untuk memverifikasi keabsahan entri dan Peserta yang Memenuhi Syarat (termasuk identitas Peserta yang Memenuhi Syarat dan tempat tinggal) dan mendiskualifikasi Peserta yang Memenuhi Syarat yang mengirimkan entri yang tidak sesuai dengan Syarat dan Ketentuan ini atau yang mengutak-atik proses entri. Kegagalan Penyelenggara untuk menegakkan hak-haknya pada tahap apa pun bukan merupakan pengabaian atas hak-hak tersebut." />
                  </li>
                  <li>
                    <Paragraph value="Kewajiban pajak yang timbul bagi pemenang hadiah akan dibayarkan oleh pemenang sebelum mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Kuis ini dilaksanakan sampai dengan 15 Agustus 2024." />
                  </li>
                </ul>
                <Paragraph value="Penjurian akan dilakukan oleh Penyelenggara, sesuai dengan instruksi dari Kuis ini yang diumumkan oleh Tim Penyelenggara di Tokopedia Penyelenggara dan berdasarkan jawaban benar maksimum yang diposting atau dipilih." />
                <Button
                  className={styles.buttonConditions}
                  value="Kembali"
                  onClick={() => {
                    setShowConditions(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Result;
